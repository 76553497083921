import { type CoreConfiguration } from '@adyen/adyen-web/auto';
import { getEnvironment } from './config';

interface AdyenConfigurationType extends Partial<CoreConfiguration> {
    sessionId: string;
    sessionData: string;
    locale: string;
}

const isLive = getEnvironment('REACT_APP_ENV') === 'production';

export const getAdyenConfiguration = (
    config: Partial<AdyenConfigurationType>
): CoreConfiguration => {
    const {
        sessionId,
        sessionData,
        onPaymentFailed,
        onPaymentCompleted,
        onError,
        locale,
        beforeSubmit,
    } = config;

    return {
        // Adyen client key has two environments: 'live' or 'test'.
        // We only use 'live' on production, and use 'test' in others.
        environment: isLive ? 'live' : 'test',
        // Public key used for client-side authentication.
        // https://docs.adyen.com/development-resources/client-side-authentication
        clientKey: getEnvironment('REACT_APP_ADYEN_CLIENT_KEY'),
        locale,
        analytics: { enabled: isLive },
        // Payment session from Adyen
        session: {
            id: sessionId || '',
            sessionData,
        },
        onPaymentCompleted,
        onPaymentFailed,
        onError,
        beforeSubmit,
    };
};

/** This specific error is already known to us.
 * This error is thrown even when there is no error at all, and we are not trying to load any iframes,
 * Even if the iframe fails to load, we still display the legacy payment method. So it is safe to exclude it from Sentry.
 * */
export const KNOWN_ADYEN_IFRAME_ERROR_MESSAGE = 'secured field iframes have failed to load';
